document.getElementById('interactive-heading').addEventListener('click', function() {
    alert("Don't click that my guy!");
});
document.getElementById('twitter-text').addEventListener('click', function() {
    alert("Oi - Don't click that bro.");
});
const heading = document.getElementById('interactive-heading');

heading.addEventListener('mouseenter', () => {
    let isHovering = true;

    function getRandom() {
        return Math.random() * 2 - 1;
    }

    function updateHeadingPosition(e) {
        if (isHovering) {
            const { clientX, clientY } = e;
            const { left, top, width, height } = heading.getBoundingClientRect();

            const centerX = left + width / 2;
            const centerY = top + height / 2;

            const deltaX = clientX - centerX;
            const deltaY = clientY - centerY;

            const angle = Math.atan2(deltaY, deltaX);
            const distance = Math.min(50, Math.hypot(deltaX, deltaY));

            const offsetX = Math.cos(angle) * distance * 0.5;
            const offsetY = Math.sin(angle) * distance * 0.5;

            heading.style.transform = `translate(${offsetX}px, ${offsetY}px)`;
        }
    }

    function vibrateHeading() {
        if (isHovering) {
            const vibrationStrength = 5;
            const offsetX = getRandom() * vibrationStrength;
            const offsetY = getRandom() * vibrationStrength;

            heading.style.transform = `translate(${offsetX}px, ${offsetY}px)`;

            requestAnimationFrame(vibrateHeading);
        }
    }

    document.addEventListener('mousemove', updateHeadingPosition);
    vibrateHeading();

    heading.addEventListener('mouseleave', () => {
        isHovering = false;
        heading.style.transform = ''; // Reset heading position
        document.removeEventListener('mousemove', updateHeadingPosition);
    });
});
